import {
  FOOTER_POPULAR_COMPANIES_BUTTON, FOOTER_TERMS_LINK,
  ET_POPULAR_COMPANIES_CLICK, EP_FOOTER, ET_TERMS_OF_USE_CLICK,
  ET_PRIVACY_POLICY_CLICK, FOOTER_PRIVACY_POLICY_LINK,
  ET_INSTANT_CREDIT_REPORT_CLICK,
  FOOTER_INSTANT_CREDIT_REPORT_BUTTON, ET_INDUSTRY_INSIGHTS_CLICK,
  FOOTER_INDUSTRY_INSIGHTS_BUTTON, ET_COMPANY_HEALTHCHECK_CLICK,
  FOOTER_COMPANY_HEALTHCHECK_BUTTON, ET_BLOG_CLICK, FOOTER_BLOG_BUTTON,
  ET_TWITTER_CLICK, FOOTER_TWITTER_BUTTON, ET_LINKEDIN_CLICK, FOOTER_LINKEDIN_BUTTON,
  FOOTER_FAIR_USAGE_POLICY_BUTTON, ET_FAIR_USAGE_POLICY_CLICK,
  FOOTER_ACCEPTABLE_USE_BUTTON, ET_ACCEPTABLE_USE_CLICK, FOOTER_COOKIE_POLICY_BUTTON,
  ET_COOKIE_POLICY_CLICK, ET_ABOUT_US_CLICK, FOOTER_ABOUT_US_BUTTON, FOOTER_FAQ_BUTTON,
  ET_FAQ_CLICK, ET_BUSINESS_VALUATION_CLICK, FOOTER_BUSINESS_VALUATION_BUTTON, FOOTER_CONTACT_US_BUTTON,
  ET_POMANDA_API_SERVICE_CLICK,
  ET_BUSINESS_PLAN_CLICK,
  FOOTER_BUSINESS_PLAN_BUTTON,
  ET_PLANS_PRICING_CLICK,
  FOOTER_PLANS_PRICING_BUTTON,
  ET_WATCHLISTS_CLICK,
  FOOTER_WATCHLISTS_BUTTON,
  ET_COMPANY_AND_DIRECTOR_CLICK,
  FOOTER_COMPANY_AND_DIRECTOR_BUTTON,
  FOOTER_LEAD_GENERATION_BUTTON,
  ET_LEAD_GENERATION_CLICK,
  ET_FINANCIAL_ESTIMATES_CLICK,
  FOOTER_FINANCIAL_ESTIMATES_BUTTON,
  FOOTER_FOR_ADVISOR_BUTTON,
  ET_FOR_SALES,
  FOOTER_FOR_SALES_BUTTON,
  ET_FOR_BUSINESS,
  FOOTER_FOR_BUSINESS_BUTTON,
  FOOTER_FOR_INVESTORS_BUTTON,
  ET_FOR_INVESTORS,
  FOOTER_ACCOUNTANCY_BUTTON,
  ET_ACCOUNTANCY_CLICK,
  ET_PRIVATE_EQUITY_CLICK,
  FOOTER_PRIVATE_EQUITY_BUTTON,
  ET_SALES_MARKETING_CLICK,
  FOOTER_SALES_MARKETING_BUTTON,
  ET_FOR_ADVISOR,
  FOOTER_POMANDA_API_CLICK,
  ET_DATA_CLEANSING_CLICK,
  FOOTER_DATA_CLEANSING_BUTTON,
} from '../../../common/trackingConstants';

export const getHomepageFooterData = (getActivityTracking, goToPowerSearchPage) => [
  {
    label: "Solutions",
    fields: [
      {
        text: "for Advisors",
        renderUrl: "/solutions/advisors",
        onClickFun: () => getActivityTracking({
          eventPage: EP_FOOTER,
          eventType: ET_FOR_ADVISOR,
          attribute1: FOOTER_FOR_ADVISOR_BUTTON
        })
      },
      {
        text: "for Sales",
        renderUrl: "/solutions/sales",
        onClickFun: () => getActivityTracking({
          eventPage: EP_FOOTER,
          eventType: ET_FOR_SALES,
          attribute1: FOOTER_FOR_SALES_BUTTON
        })
      },
      {
        text: "for Investors",
        renderUrl: "/solutions/investors",
        onClickFun: () => getActivityTracking({
          eventPage: EP_FOOTER,
          eventType: ET_FOR_INVESTORS,
          attribute1: FOOTER_FOR_INVESTORS_BUTTON
        })
      },
      {
        text: "for Business",
        renderUrl: "/solutions/business",
        onClickFun: () => getActivityTracking({
          eventPage: EP_FOOTER,
          eventType: ET_FOR_BUSINESS,
          attribute1: FOOTER_FOR_BUSINESS_BUTTON
        })
      },
      {
        text: "Pomanda API",
        renderUrl: "/pomanda-api-service",
        onClickFun: () => {
          getActivityTracking({
            eventPage: EP_FOOTER,
            eventType: ET_POMANDA_API_SERVICE_CLICK,
            attribute1: FOOTER_POMANDA_API_CLICK
          })
        }
      },
      {
        text: "Data Cleansing",
        renderUrl: "/solutions/datacleansing",
        onClickFun: () => {
          getActivityTracking({
            eventPage: EP_FOOTER,
            eventType: ET_DATA_CLEANSING_CLICK,
            attribute1: FOOTER_DATA_CLEANSING_BUTTON
          })
        }
      }
    ]
  },
  {
    label: "Use Case",
    fields: [
      {
        text: "Accountancy",
        renderUrl: "/sector/accountants",
        onClickFun: () => getActivityTracking({
          eventPage: EP_FOOTER,
          eventType: ET_ACCOUNTANCY_CLICK,
          attribute1: FOOTER_ACCOUNTANCY_BUTTON
        })
      },
      {
        text: "Private Equity",
        renderUrl: "/sector/privateequity",
        onClickFun: () => getActivityTracking({
          eventPage: EP_FOOTER,
          eventType: ET_PRIVATE_EQUITY_CLICK,
          attribute1: FOOTER_PRIVATE_EQUITY_BUTTON
        })
      },
      {
        text: "Sales & Marketing",
        renderUrl: "/sector/salesandmarketing",
        onClickFun: () => getActivityTracking({
          eventPage: EP_FOOTER,
          eventType: ET_SALES_MARKETING_CLICK,
          attribute1: FOOTER_SALES_MARKETING_BUTTON
        })
      },
    ]
  },
  {
    label: "Resources",
    fields: [
      {
        text: "Value A Business",
        renderUrl: "/solutions/companyvaluation",
        onClickFun: () => getActivityTracking({
          eventPage: EP_FOOTER,
          eventType: ET_BUSINESS_VALUATION_CLICK,
          attribute1: FOOTER_BUSINESS_VALUATION_BUTTON
        })
      },
      {
        text: "AI Business Plans",
        renderUrl: "/solutions/businessplan",
        onClickFun: () => getActivityTracking({
          eventPage: EP_FOOTER,
          eventType: ET_BUSINESS_PLAN_CLICK,
          attribute1: FOOTER_BUSINESS_PLAN_BUTTON
        })
      },
      {
        text: "Lead Generation",
        renderUrl: "/resources/leadgeneration",
        onClickFun: () => getActivityTracking({
          eventPage: EP_FOOTER,
          eventType: ET_LEAD_GENERATION_CLICK,
          attribute1: FOOTER_LEAD_GENERATION_BUTTON
        })
      },
      {
        text: "Financial Estimates",
        renderUrl: "/solutions/estimates",
        onClickFun: () => getActivityTracking({
          eventPage: EP_FOOTER,
          eventType: ET_FINANCIAL_ESTIMATES_CLICK,
          attribute1: FOOTER_FINANCIAL_ESTIMATES_BUTTON
        })
      },
      {
        text: "Find Companies & Directors",
        renderUrl: "/solutions/companyinformation",
        onClickFun: () => getActivityTracking({
          eventPage: EP_FOOTER,
          eventType: ET_COMPANY_AND_DIRECTOR_CLICK,
          attribute1: FOOTER_COMPANY_AND_DIRECTOR_BUTTON
        })
      },
      {
        text: "Instant Credit Reports",
        renderUrl: "/solutions/companycreditcheck",
        onClickFun: () => getActivityTracking({
          eventPage: EP_FOOTER,
          eventType: ET_INSTANT_CREDIT_REPORT_CLICK,
          attribute1: FOOTER_INSTANT_CREDIT_REPORT_BUTTON
        })
      },
      {
        text: "Company Health Check",
        renderUrl: "/solutions/scorecard",
        onClickFun: () => getActivityTracking({
          eventPage: EP_FOOTER,
          eventType: ET_COMPANY_HEALTHCHECK_CLICK,
          attribute1: FOOTER_COMPANY_HEALTHCHECK_BUTTON
        })
      },
      {
        text: "Industry Insights",
        renderUrl: "/solutions/industry",
        onClickFun: () => getActivityTracking({
          eventPage: EP_FOOTER,
          eventType: ET_INDUSTRY_INSIGHTS_CLICK,
          attribute1: FOOTER_INDUSTRY_INSIGHTS_BUTTON
        })
      },
      {
        text: "Watchlists",
        renderUrl: "/resources/leadgeneration?id=watchlists",
        onClickFun: () => getActivityTracking({
          eventPage: EP_FOOTER,
          eventType: ET_WATCHLISTS_CLICK,
          attribute1: FOOTER_WATCHLISTS_BUTTON
        })
      }
    ]
  },
  {
    label: "Power Search",
    fields: [
      {
        text: "Search Companies",
        renderUrl: "/powersearch/company",
        onClickFun: () => goToPowerSearchPage("company", true)
      },
      {
        text: "Search Shareholders",
        renderUrl: "/powersearch/shareholder",
        onClickFun: () => goToPowerSearchPage("shareholder", false),
      },
      {
        text: "Search Directors",
        renderUrl: "/powersearch/director",
        onClickFun: () => goToPowerSearchPage("director", true),
      },
      {
        text: "Search Industries",
        renderUrl: "/powersearch/industry",
        onClickFun: () => goToPowerSearchPage("industry", false),
      },
      {
        text: "Search Regions",
        renderUrl: "/powersearch/region",
        onClickFun: () => goToPowerSearchPage("region", false),
      },
    ]
  },
  {
    label: "Pomanda",
    fields: [
      {
        text: "Plans & Pricing",
        renderUrl: "/product",
        isProsperRectrictedUrl: true,
        onClickFun: () => {
          getActivityTracking({
            eventPage: EP_FOOTER,
            eventType: ET_PLANS_PRICING_CLICK,
            attribute1: FOOTER_PLANS_PRICING_BUTTON
          })
        }
      },
      {
        text: "About Us",
        renderUrl: "/about",
        onClickFun: () => {
          getActivityTracking({
            eventPage: EP_FOOTER,
            eventType: ET_ABOUT_US_CLICK,
            attribute1: FOOTER_ABOUT_US_BUTTON
          })
        }
      },
      {
        text: "Blog",
        renderUrl: "/blog",
        onClickFun: () => {
          getActivityTracking({
            eventPage: EP_FOOTER,
            eventType: ET_BLOG_CLICK,
            attribute1: FOOTER_BLOG_BUTTON
          })
        }
      },
      {
        text: "Help Centre",
        renderUrl: "/help",
        onClickFun: () => {
          getActivityTracking({
            eventPage: EP_FOOTER,
            eventType: ET_FAQ_CLICK,
            attribute1: FOOTER_FAQ_BUTTON
          })
        }
      },
      {
        text: "Contact Us",
        renderUrl: "/contact",
        onClickFun: () => {
          getActivityTracking({
            eventPage: EP_FOOTER,
            eventType: ET_ABOUT_US_CLICK,
            attribute1: FOOTER_CONTACT_US_BUTTON
          })
        }
      }, {
        text: "Popular Companies",
        renderUrl: "/popular-companies/a/1",
        onClickFun: () => {
          getActivityTracking({
            eventPage: EP_FOOTER,
            eventType: ET_POPULAR_COMPANIES_CLICK,
            attribute1: FOOTER_POPULAR_COMPANIES_BUTTON

          })
        }
      },
    ]
  },
  {
    label: "Conditions",
    fields: [
      {
        text: "Terms of Use",
        renderUrl: "/assets/Download/Terms_Of_Use.pdf",
        redirectWindow: true,
        onClickFun: () => {
          getActivityTracking({
            eventPage: EP_FOOTER,
            eventType: ET_TERMS_OF_USE_CLICK,
            attribute1: FOOTER_TERMS_LINK,
          })
        }
      },
      {
        text: "Privacy Policy",
        renderUrl: "/assets/Download/Pomanda_Privacy_Notice.pdf",
        redirectWindow: true,
        onClickFun: () => {
          getActivityTracking({
            eventPage: EP_FOOTER,
            eventType: ET_PRIVACY_POLICY_CLICK,
            attribute1: FOOTER_PRIVACY_POLICY_LINK,
          })
        }
      },
      {
        text: "Cookie Policy",
        renderUrl: "/assets/Download/Cookies_May20.pdf",
        redirectWindow: true,
        onClickFun: () => {
          getActivityTracking({
            eventPage: EP_FOOTER,
            eventType: ET_COOKIE_POLICY_CLICK,
            attribute1: FOOTER_COOKIE_POLICY_BUTTON
          })
        }
      },
      {
        text: "Acceptable Use",
        renderUrl: "/assets/Download/Website_acceptable_use.pdf",
        redirectWindow: true,
        onClickFun: () => {
          getActivityTracking({
            eventPage: EP_FOOTER,
            eventType: ET_ACCEPTABLE_USE_CLICK,
            attribute1: FOOTER_ACCEPTABLE_USE_BUTTON
          })
        }
      },
      {
        text: "Fair Usage Policy",
        renderUrl: "/assets/Download/Fair_Usage_Policy.pdf",
        redirectWindow: true,
        onClickFun: () => {
          getActivityTracking({
            eventPage: EP_FOOTER,
            eventType: ET_FAIR_USAGE_POLICY_CLICK,
            attribute1: FOOTER_FAIR_USAGE_POLICY_BUTTON
          })
        }
      }
    ]
  },
  {
    label: "Social",
    fields: [
      {
        text: 'X (ex. Twitter)',
        renderUrl: "https://twitter.com/PomandaCo",
        redirectWindow: true,
        isAbsoluteUrl: true,
        onClickFun: () => {
          getActivityTracking({
            eventPage: EP_FOOTER,
            eventType: ET_TWITTER_CLICK,
            attribute1: FOOTER_TWITTER_BUTTON
          })
        }
      },
      {
        text: 'LinkedIn',
        isAbsoluteUrl: true,
        renderUrl: "https://linkedin.com/company/pomanda",
        redirectWindow: true,
        onClickFun: () => {
          getActivityTracking({
            eventPage: EP_FOOTER,
            eventType: ET_LINKEDIN_CLICK,
            attribute1: FOOTER_LINKEDIN_BUTTON
          })
        }
      }
    ]
  }
]

