import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import {
    sendActivityTracking, setChoosePackageModalData,
    setHeaderSingleSearchOptions, setHideHeader
} from '../../redux/actions/commonAction';
import {
    resetAllSearchParams,
} from '../../redux/actions/searchAction';
import { setCompanyProfile } from '../../redux/actions/companyAction';
import SingleSearchBar from '../SingleSearchBar/SingleSearchBar';
import analyticsApi from '../../api/analyticsApi';
import {
    EP_HOMEPAGE, ET_HOMEPAGE_ONLOAD, ET_HOMEPAGE_SINGLE_SEARCH,
    HOMEPAGE_SEARCHBAR_SEARCH, HOMEPAGE_SEARCHBAR_OPTION, HOMEPAGE_SEARCHBAR_ICON,
    HOMEPAGE_SEARCHBAR_ENTER,
    ET_PRODUCT_HOMEPAGE_SEARCHBAR_ENTER_CLICK
} from '../../common/trackingConstants';
import './HomepageVer3.css';
import { checkResetPasswordLinkValid } from '../../redux/actions/loginSignupAction';
import { isBrowser, PROSPER_USER_TYPE, REACT_APP_GOOGLE_INDEX } from '../../common/config';
import { Helmet } from "react-helmet";
import MediaQuery from 'react-responsive';
import HomePageCardComponent from '../HomePageCard/HomePageCardComponent';
import { getSolutionMenuItems } from '../WhatWeDo/Constants';
import HomepageFooterV3 from '../HomePage/HomepageFooterV3/HomepageFooterV3';
import { SR_TRY_POMANDA_PLUS } from '../../common/subscriptionConfigConst';
import DynamicSvg from '../../DynamicSvg';
import WhatPomanda from '../WhatIsPomanda/WhatPomanda';
import YellowBgButton from '../BusinessPlanTemplate/BusinessPlanComponents/YellowBgButton/YellowBgButton';
import Carousel from 'react-multi-carousel';
import { CarouselHomepageSolutionsCard } from '../../common/CarouselConfig';
import { Link } from 'react-router-dom';
import TestimonyCard from './TestimonyCard/TestimonyCard';
import TestimonialCarouselComponent from './TestimonialCarouselComponent/TestimonialCarouselComponent';
import OurFeatures from '../OurFeaturesComponent/OurFeature';

const testimonials = [{
    name: "Russell Kenrick",
    role: "ILX Group PLC",
    description: "Pomanda has not only simplified our lead generation process but has also provided us with a wealth of industry insights and business information. This has been instrumental in helping us make informed decisions and gain a deeper understanding of our marketplace and the companies operating within it."
}, {
    name: "Lee D",
    role: "SME Owner",
    description: "5 stars- I needed to have my company valued so that I could give my son some shares as he works here. It was so easy and I wasn't then bombarded with calls. The report want perfect for what I needed and not too expensive either."
}]

class HomepageVer3 extends Component {

    constructor() {
        super();
        this.state = {
            searchtext: ""
        }
    }

    componentDidMount() {
        const {
            match = {},
            companyQueryData = {}
        } = this.props;
        this.props.dispatchSetHeaderSingleSearchOptions({
            tryPowerSearch: true,
            eventPage: EP_HOMEPAGE
        });
        this.props.dispatchSetCompanyProfile({})
        analyticsApi.onPageLoad(window.location.pathname)
        this.props.dispatchSendActivityTracking({
            eventPage: EP_HOMEPAGE,
            eventType: ET_HOMEPAGE_ONLOAD
        });
        window.scrollTo({ top: 0 });
        if (match.params && match.params.email && match.params.email !== "") {
            this.props.dispatchCheckResetPasswordLinkValidity(match.params.email);
        }
        let newCompanyQueryData = JSON.parse(JSON.stringify(companyQueryData))
        newCompanyQueryData.isolatedSearchText = ""
        this.props.dispatchResetAllSearchParams(newCompanyQueryData);
        this.handleIntersectionObservor("lazy-bg")
    }

    componentDidUpdate = (prevProps, _prevState) => {
        const {
            headerSearchText = "",
            headerProps = {}
        } = this.props;
        if (headerSearchText && headerSearchText !== prevProps.headerSearchText) {
            this.setState({ searchtext: "" });
        }
        headerProps?.backgroundDesktop !== "transparent" && this.props.dispatchSetHideHeader({
            backgroundDesktop: "transparent",
            backgroundTab: "transparent",
            backgroundMob: "transparent",
            isSingleSearchbarSticky: false,
        });

    }

    componentWillUnmount = () => {
        analyticsApi.onPageUnLoad();
        this.props.dispatchSetHeaderSingleSearchOptions({
            tryPowerSearch: false,
        });
        this.props.dispatchSetHideHeader({
            backgroundTab: "blue",
            backgroundMob: "blue",
            backgroundDesktop: "blue",
            isSingleSearchbarSticky: true,
        });
        window.scrollTo({ top: 0 });
    }

    onButtonClick = (eventType) => {
        this.props.dispatchSendActivityTracking({
            eventPage: EP_HOMEPAGE,
            eventType
        });
    }

    handleTryNowClick = () => {
        const { userInfo = {} } = this.props;
        let path = ""
        if (userInfo?.userType === PROSPER_USER_TYPE) {
            path = "/powersearch/company";
        } else {
            const pathname = isBrowser && window.location.pathname
            const href = isBrowser && window.location.href
            path = `/product?serviceReq=${SR_TRY_POMANDA_PLUS}&url=${pathname}&href=${href}`
        }
        this.props.history.push(path);
    }

    supportWebp = () => {
        return document.documentElement.getAttribute('class').split(" ").some((className) => className === 'webp');
    }

    handleIntersectionObservor = (className) => {
        const targetElements = document.querySelectorAll(`${className ? '.' + className : ""}`);
        const observer = new IntersectionObserver((entries, observer) => {
            entries.map((entry) => {
                if (entry.isIntersecting) {
                    const lazyBgElement = entry.target;
                    lazyBgElement.classList.add("lazyloaded-bg");
                    lazyBgElement.classList.remove('lazy-bg');
                    observer.unobserve(lazyBgElement);
                }
            })
        });
        targetElements.length > 0 && targetElements.forEach((target) => {
            observer.observe(target);
        })
    }

    render() {
        const {
            staticContext = {},
            history = {},
            userInfo = {},
            whiteLabelConfig = {}
        } = this.props;
        let href, origin;
        if (isBrowser) {
            href = window.location.href
            origin = window.location.origin
        } else {
            href = `${staticContext.baseHref}${history.location.pathname}`
            origin = staticContext.baseHref
        }
        const iswhiteLabelConfig = whiteLabelConfig?.header ? true : false
        return (
            <div className="homepageVer3">
                <Helmet>
                    <title>UK Company Information for Sales, Valuation, Research & Compliance | Pomanda</title>
                    {!REACT_APP_GOOGLE_INDEX && <meta name="robots" content="noindex" />}
                    <meta name="description" content="Pomanda provides Company Information, Financials and Valuations for all UK companies to aid Sales, Business Improvement and Company Analysis" />
                    <meta property="og:title" content="Pomanda" />
                    <meta property="og:description" content="Unrivalled UK Company Information | Pomanda" />
                    <link rel="canonical" href={href} />
                    <script type='application/ld+json' data-react-helmet="true">
                        {`{
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "url": "https://www.pomanda.com",
                            "sameAs": ["https://www.linkedin.com/company/pomanda", "https://www.twitter.com/pomanda"],
                            "logo": "https://www.pomanda.com/favicon.ico",
                            "name": "Pomanda",
                            "description": "Pomanda is a company information platform that provides the most complete data on the entire UK market, including SMEs.",
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "3 Waterhouse Square",
                                "addressLocality": "London",
                                "addressCountry": "England",
                                "addressRegion": "London",
                                "postalCode": "EC1N 2SW"
                            },
                            "vatID": "GB 238771183",
                            "contactPoint": {
                                "@type": "ContactPoint",
                                "email": "theteam@pomanda.com",
                                "telephone": "+442038655908"
                            }
                        }`}

                    </script>
                </Helmet>
                <div className='homepageV3-header-section lazy-bg'>
                    <video className="homepageV3-bg-video" preload='auto' autoPlay loop muted playsInline webkit-playsinline="true">
                        <source src="/assets/videos/homepage_bg_video.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="homepageV3-content-main homepageV3-content-max">
                        <h1 className="homepageV3-heading homepageV3-heading-max" pomandae2e="homepage">The UK's Best Company Information</h1>
                        <h2 className="homepageV3-header-content homepageV3-heading-max mb-0">
                            For Lead Generation, Industry Research and SME Business Insight.
                        </h2>
                        <div className='homepageV3-searchbar-info-wrapper homepageV3-searchbar-max mx-auto'>
                            <div className="homepageNew-searchbar-section">
                                <SingleSearchBar
                                    formId="single_search_new_homepage"
                                    searchButtonPlaceholder="Search for a Company or Director..."
                                    searchTextboxValue={this.state.searchtext}
                                    setSearchTextboxValue={(text) => this.setState({ searchtext: text })}
                                    inputId="single_search_input_new_homepage"
                                    eventCreatedKeyDown={HOMEPAGE_SEARCHBAR_ENTER}
                                    eventTypeKeyDown={ET_PRODUCT_HOMEPAGE_SEARCHBAR_ENTER_CLICK}
                                    eventPage={EP_HOMEPAGE}
                                    eventTypeSearch={ET_HOMEPAGE_SINGLE_SEARCH}
                                    eventCreatedSearch={HOMEPAGE_SEARCHBAR_SEARCH}
                                    eventCreatedOptionClick={HOMEPAGE_SEARCHBAR_OPTION}
                                    eventCreatedIconClick={HOMEPAGE_SEARCHBAR_ICON}
                                    type="homepageSearchbar"
                                    singleSearchTypes={[]}
                                    showSearchText={false}
                                    inputBeforeIcon={{
                                        imageUrl: "/assets/images/logo_pom_black.png",
                                        webpImageUrl: "/assets/webpImages/logo_pom_black.webp",
                                        alt: "logo_pom_black",
                                        width: 23,
                                        height: 23
                                    }}
                                />
                            </div>
                            <h2 className='homepageV3-searchbar-info homepageV3-searchbar-max mb-0'>Use Pomanda to increase the value of your business, find new customers and protect against risk.</h2>
                        </div>
                    </div>
                </div>
                <div className="homepageV3-solutions-section homepageV3-content-max">
                    <h3 className="homepageV3-solutions-label">Our Solutions</h3>
                    <MediaQuery minWidth={576}>
                        <HomePageCardComponent
                            cardDataList={getSolutionMenuItems(userInfo?.userType, iswhiteLabelConfig)}
                            onButtonClick={(e) => this.onButtonClick(e)}
                            withOutLazyLoading={false}
                        />
                    </MediaQuery>
                    <MediaQuery maxWidth={575.98}>
                        <div className='solutions-card-carousel-component'>
                            <Carousel
                                arrows={false}
                                ssr={true}
                                infinite={true}
                                swipeable={true}
                                showDots
                                minimumTouchDrag={10}
                                partialVisible={true}
                                sliderClass='solutions-carousel-track'
                                itemClass='solutions-carousel-card'
                                containerClass="solutions-card-carousel w-100"
                                slidesToSlide={CarouselHomepageSolutionsCard['mobile']?.slidesToSlide || 1}
                                responsive={CarouselHomepageSolutionsCard}
                            >
                                {getSolutionMenuItems(userInfo?.userType, iswhiteLabelConfig).map((data) => <MobileSolutionCard
                                    data={data}
                                    onButtonClick={(e) => this.onButtonClick(e)}
                                />)}
                            </Carousel>
                        </div>
                    </MediaQuery>
                </div>
                <div className='homepageV3-why-pomanda-section'>
                    <div className='homepageV3-why-pomanda-label-wrapper'>
                        <div className='homepageV3-why-pomanda-label homepageV3-content-max'>
                            <picture >
                                <source srcSet="/assets/webpImages/logo_pom.webp" />
                                <img src="/assets/images/logo_pom.png" className="use-pom-card-img"
                                    width="14" height="14" alt="Pomanda_icon" />
                            </picture>
                            <p>The most comprehensive source of company information on UK private companies</p>
                            <MediaQuery minWidth={576}>
                                <picture >
                                    <source srcSet="/assets/webpImages/logo_pom.webp" />
                                    <img src="/assets/images/logo_pom.png" className="use-pom-card-img"
                                        width="14" height="14" alt="Pomanda_icon" />
                                </picture>
                            </MediaQuery>
                        </div>
                    </div>
                    <div className='our-features-section'>
                        <div className='our-feature-headings-wrapper'>
                            <h5 className='our-features-heading mb-0'>Our Features</h5>
                            <h3 className='homepageV3-why-pomanda-heading homepageV3-content-max'>Why Use Pomanda?</h3>
                            <p className='our-features-subheading mb-0'>Choose Pomanda for access to a comprehensive suite of data-driven tools tailored specifically for business owners and professionals.</p>
                        </div>
                        <OurFeatures />
                    </div>
                </div>
                <div className='homepageV3-testimonials-wrapper'>
                    <div className='homepageV3-testimonials-section'>
                        <p className='homepageV3-testimony-header text-sm-center mb-0'>Testimonials</p>
                        <MediaQuery minWidth={992}>
                            <div className='homepageV3-testimonials-container'>
                                {testimonials.map((testimonyObj) => {
                                    return <TestimonyCard
                                        {...testimonyObj}
                                    />
                                })}
                            </div>
                        </MediaQuery>
                        <MediaQuery maxWidth={991.98}>
                            <TestimonialCarouselComponent
                                testimonials={testimonials}
                            />
                        </MediaQuery>
                    </div>
                </div>
                <WhatPomanda
                    imageUrl="/assets/images/what_pomanda.png"
                    webpImageUrl="/assets/webpImages/what_pomanda.webp"
                    pomandaDetails={
                        {
                            mainHeading: "What is Pomanda?",
                            description: "Pomanda is your go-to comprehensive company information platform, providing extensive data coverage across the entire UK market, including SMEs. By enhancing Companies House data with over 1 billion new data points, we unlock valuable insights into the sales, growth, and profitability of every UK company. As your primary destination for company data, our platform serves as a complete SME toolkit, offering a range of subscriptions and products that leverage our wealth of data.",
                            btnComp: {
                                btnLabel: "LEARN MORE",
                                btnCss: "what-pomanda-learn-more-btn",
                                onBtnClick: () => this.props.history.push(`/about`),
                                ButtonComp: YellowBgButton
                            },
                            pomandaStats: [{
                                heading: "1B+",
                                subHeading: "NEW DATA POINTS"
                            },
                            {
                                heading: "17M+",
                                subHeading: "SHAREHOLDERS"
                            },
                            {
                                heading: "33M+",
                                subHeading: "COMPANY & DIRECTOR PROFILES"
                            },
                            {
                                heading: "10K+",
                                subHeading: "VALUATION REPORTS RUN"
                            }]
                        }
                    } />

                <div className="homepage-footer-section">
                    <HomepageFooterV3 />
                </div>
            </div>
        );
    }
}

const MobileSolutionCard = (props) => {
    const { data = {} } = props;
    const [mouseHover, setMouseHover] = useState("");
    const imageAttObj = {
        'src': `/assets/svgs/${mouseHover === data.id ? data.iconOnHover : data.icon}`,
        width: data.width ? data.width : "44",
        height: data.height ? data.height : "44",
        alt: data.alt ? data.alt : data.cardHeading,
        className: `${mouseHover === data.id ? "d-none" : ''}`
    }

    return <div className='home-page-card-outer-div'>
        <div className="home-page-card-feature-container-section">
            <Link to={`/${data.link}`} onClick={
                () => props.onButtonClick(data.eventType)}>
                <div className="home-page-card-feature-section" onMouseOver={() => setMouseHover(data.id)} onMouseOut={() => setMouseHover("")}>
                    <div className="home-page-card-icon-style">
                        <img src={`/assets/svgs/${data.iconOnHover}`}
                            width={data.width ? data.width : "44"}
                            height={data.height ? data.height : "44"}
                            alt={data.alt ? data.alt : data.cardHeading}
                            className={`lazyload ${mouseHover !== data.id ? "d-none" : ''}`}>
                        </img>
                        <DynamicSvg {...imageAttObj} />
                    </div>
                    <div className="home-page-card-heading" pomandae2e={data.cardHeading}>{data.cardHeading}</div>
                    <div className="home-page-card-text" pomandae2e={data.cardText}>{data.cardText}</div>
                    <div className="home-page-card-button" pomandae2e={data.button}> {data.button} </div>
                </div>
            </Link>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    headerSearchText: state.common.headerSearchText,
    headerSingleSearchOptions: state.common.headerSingleSearchOptions,
    headerProps: state.common.headerProps,
    userInfo: state.userProfile.userInfo,
    whiteLabelConfig: state.common.whiteLabelConfig,
    headerProps: state.common.headerProps
})

const mapDispatchToProps = (dispatch) => ({
    dispatchSetCompanyProfile: data => dispatch(setCompanyProfile(data)),
    dispatchCheckResetPasswordLinkValidity: (email) => dispatch(checkResetPasswordLinkValid(email)),
    dispatchSendActivityTracking: (trackingObj) => dispatch(sendActivityTracking(trackingObj)),
    dispatchSetHeaderSingleSearchOptions: optionObj => dispatch(setHeaderSingleSearchOptions(optionObj)),
    dispatchSetHideHeader: (headerProps) => dispatch(setHideHeader(headerProps)),
    // dispatchSetChoosePackageModalData: (modalData) => dispatch(setChoosePackageModalData(modalData))
    dispatchResetAllSearchParams: searchParam => dispatch(resetAllSearchParams(searchParam)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomepageVer3)