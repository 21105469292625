import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './HomepageFooterV3.css';
import { withRouter } from 'react-router';
import MediaQuery from 'react-responsive';
import { toggleLoginModal } from '../../../redux/actions/loginSignupAction';
import localStorageApi from '../../../api/localStorageApi';
import { HOMEPAGE_FOOTER_SEARCH_BUTTON, EP_FOOTER, FOOTER_CONTACT_US_LINK } from '../../../common/trackingConstants';
import { sendActivityTracking } from '../../../redux/actions/commonAction';
import { setActivityTrackingData } from '../../../redux/actions/searchAction';
import analyticsApi from '../../../api/analyticsApi';
import { GA_PS_BTN_TAG, PROSPER_USER_TYPE, isBrowser } from "../../../common/config";
import { TOGGLE_LOGIN_MODAL_ON_SUCCESS } from '../../../common/loginSignupSuccessConstants';
import { getOnloadEventType } from '../../common/PowerSearchFunctions';
import { toggleSubmitInvestmentInterestModal } from '../../../redux/actions/companyAction';
import { getHomepageFooterData } from './HomePageFooterData';
class HomepageFooterV3 extends Component {

    getActivityTracking = (actvityObj) => {
        this.props.dispatchSendActivityTracking(actvityObj);
    }

    goToPowerSearchPage = (searchType, isRestrictRedirect) => {
        this.props.setActivityTrackingData({ obj: { "eventCreated": HOMEPAGE_FOOTER_SEARCH_BUTTON, "eventType": getOnloadEventType(searchType) }, type: "powerSearch" })
        if (!isRestrictRedirect) {
            switch (searchType) {
                case "company":
                    this.props.history.push("/powersearch/company");
                    break;
                case "director":
                    this.props.history.push("/powersearch/director");
                    break;
                case "industry":
                    this.props.history.push("/powersearch/industry");
                    break;
                case "charges":
                    this.props.history.push("/powersearch/charges");
                    break;
                case "shareholder":
                    this.props.history.push("/powersearch/shareholder");
                    break;
                case "region":
                    this.props.history.push("/powersearch/region");
                    break;
                default:
                    return {}
            }
        }
        analyticsApi.gtag_report_conversion(GA_PS_BTN_TAG)
    }

    handleContactUsClick = () => {
        this.props.dispatchSubmitInterestModal({
            toggleStatus: true,
            subject: "Contact Us",
            subHeading: "",
            heading: "Contact Us",
            userMessage: "What can we help with?",
            descriptionLabel: "Get in touch",
            mailRecipient: "CONTACT_US_EMAIL_ADDRESS",
            eventPage: EP_FOOTER,
            eventType: FOOTER_CONTACT_US_LINK
        });
    }


    render() {
        let userTrackerObj = isBrowser && localStorageApi.getUserData()
        let showAccMenu = false;
        if (userTrackerObj && userTrackerObj.accessToken && userTrackerObj.accessToken !== "") {
            showAccMenu = true;
        }
        const { whiteLabelConfig = {},
            userInfo = {}
        } = this.props;
        const iswhiteLabelConfig = whiteLabelConfig?.header ? true : false
        const isProsperTrue = userInfo?.userType === PROSPER_USER_TYPE
        const footerContentsArr = getHomepageFooterData(this.getActivityTracking, this.goToPowerSearchPage)

        return (
            <div className='footer-cmp-section'>
                <div className="footer-cmp-logo-section">
                    <>
                        {whiteLabelConfig?.footer ?
                            <picture>
                                <source type="image/webp" data-srcset={whiteLabelConfig?.footer.sourceset} />
                                <img data-src={whiteLabelConfig?.footer.image} className="lazyload" alt="pomanda" width="180" height="70" />
                            </picture> : <picture>
                                <source type="image/webp" data-srcset="/assets/svgs/pomanda_logo_white.svg" />
                                <img data-src="/assets/images/pomanda_white_yellow_logo.png" className="lazyload" alt="pomanda" width="180" height="38" />
                            </picture>}
                        <div className='footer-logo-subheading'>
                            <MediaQuery minWidth={992}>
                                <p className='white-color-text m-0'>The UK's Best Company Information for Lead Generation,</p>
                                <p className='white-color-text m-0'>Industry Search and SME Business Insight.</p>
                            </MediaQuery>
                            <MediaQuery minWidth={768} and maxWidth={991.98}>
                                <p className='white-color-text m-0'>The UK's Best Company Information for Lead Generation, Industry Search and </p>
                                <p className='white-color-text m-0'>SME Business Insight.</p>
                            </MediaQuery>
                            <MediaQuery maxWidth={576}>
                                <p className='white-color-text m-0'>The UK's Best Company Information for Lead</p>
                                <p className='white-color-text m-0'> Generation, Industry Search and SME Business Insight.</p>
                            </MediaQuery>
                        </div>
                    </>
                </div>
                <div className='footer-cmp-content-section'>
                    {footerContentsArr && footerContentsArr.map((footerContent) => {
                        const footerFields = isProsperTrue ? footerContent.fields && footerContent.fields.filter(obj => !obj.isProsperRectrictedUrl) : footerContent.fields
                        return (
                            <div className='footer-cmp-content-col' key={footerContent.label}>
                                <p className='footer-cmp-content-col-label'>{footerContent.label}</p>
                                {footerFields.map((contentFieldObj) => {
                                    if (!(contentFieldObj.text === 'Business Valuation' && iswhiteLabelConfig)) {
                                        return (
                                            <React.Fragment key={contentFieldObj.text ? contentFieldObj.text : contentFieldObj.imgAlt}>
                                                {contentFieldObj.isAbsoluteUrl ?
                                                    <a href={contentFieldObj.renderUrl} target="_blank">
                                                        {contentFieldObj.text ?
                                                            <p className='footer-cmp-content-col-field' >{contentFieldObj.text}</p> :
                                                            <span>
                                                                <picture>
                                                                    <source type="image/webp" data-srcset={contentFieldObj.imgWebp} />
                                                                    <img pomandae2e={contentFieldObj.imgAlt} data-src={contentFieldObj.img} className="footer-content-img lazyload" alt={contentFieldObj.imgAlt} height="45" width="45" />
                                                                </picture>
                                                            </span>
                                                        }
                                                    </a> : 
                                                    <Link
                                                        to={contentFieldObj.renderUrl || "/"}
                                                        target={contentFieldObj.redirectWindow ? "_blank" : "_self"}
                                                        rel={contentFieldObj.redirectWindow ? "noopener noreferrer" : ""}
                                                        onClick={(e) => {
                                                            if (contentFieldObj.onClickFun) {
                                                                contentFieldObj.onClickFun(e);
                                                            }
                                                            if (window.location.pathname === contentFieldObj.renderUrl) {
                                                                e.preventDefault(); 
                                                                window.scrollTo({
                                                                    top: 0,
                                                                    behavior: 'smooth'
                                                                });
                                                            }
                                                        }}>
                                                        {contentFieldObj.text ?
                                                            <p pomandae2e={`${contentFieldObj.text} footerMenu`} className='footer-cmp-content-col-field' >{contentFieldObj.text}</p> :
                                                            <span>
                                                                <picture >
                                                                    <source type="image/webp" data-srcset={contentFieldObj.imgWebp} />
                                                                    <img data-src={contentFieldObj.img} className="footer-content-img lazyload" alt={contentFieldObj.imgAlt} height="45" width="45" />
                                                                </picture>
                                                            </span>
                                                        }
                                                    </Link>
                                                }
                                            </React.Fragment>
                                        )
                                    }

                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    token: state.loginSignup.token,
    whiteLabelConfig: state.common.whiteLabelConfig,
    userInfo: state.userProfile.userInfo
});

const mapDispatchToProps = (dispatch) => ({
    toggleLoginModal: (toggleStatus, trackingInfo, onSuccessRender) => dispatch(toggleLoginModal(toggleStatus, trackingInfo, onSuccessRender)),
    dispatchSendActivityTracking: (trackingObj) => dispatch(sendActivityTracking(trackingObj)),
    setActivityTrackingData: trackingObj => dispatch(setActivityTrackingData(trackingObj)),
    dispatchSubmitInterestModal: (submitInterestModalObj) => dispatch(toggleSubmitInvestmentInterestModal(submitInterestModalObj))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomepageFooterV3));